import './css/fancybox.scss'
import './css/style.front.scss';

import 'jquery/dist/jquery';
import 'popper.js/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';


const Nette = require('nette-forms');

Nette.initOnLoad();

$('.mainMenu-toggler').click(function() {
	$(this).toggleClass('active');
	$('.mainMenu .mainMenu-mobileFixer').toggle();
});

// mobile submenu toggler
$('.mainMenu span.submenu-toggler').click(function() {
	const parent_li = $(this).closest('li');

	$('ul', parent_li).toggle();
	$(this).toggleClass('active');
});

$('[data-fancybox="gallery"]').fancybox({
	loop: true,
	caption: function(instance, item) {
		let caption = $(this).data('caption') || '';

		if (item.type === 'image') {
			caption = $(this).attr('title');
		}

		return caption;
	},
});
